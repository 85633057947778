import { createFile, updateOrganization } from '@nimles/react-redux';
import { UserModel, OrganizationModel } from '@nimles/models';
import { Card, CardBody } from '@nimles/react-web-components';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/types';
import { OrganizationForm } from '../forms/organization/OrganizationForm';
import { FileModel } from '@nimles/models';

export const Profile = () => {
  const dispatch = useDispatch();
  const organization = useSelector<RootState, OrganizationModel>(
    ({ organizations }) => organizations.selected
  );

  const getFileId = async (file: FileModel) => {
    if (!file) {
      return null;
    }
    if (file.id) {
      return file.id;
    }

    const fileResponse: any = await dispatch(createFile(file));

    return fileResponse.id;
  };

  const handleSubmit = async (values: any) => {
    const logoId = await getFileId(values.logo);
    const bannerId = await getFileId(values.banner);

    await dispatch(
      updateOrganization({ ...organization, ...values, logoId, bannerId })
    );
  };

  return (
    <Card>
      <CardBody>
        <OrganizationForm organization={organization} onSubmit={handleSubmit} />
      </CardBody>
    </Card>
  );
};
