import { OrganizationModel } from '@nimles/models';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from '@nimles/react-web-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/types';

export const Default = ({}) => {
  const organization = useSelector<RootState, OrganizationModel>(
    ({ organizations }) => organizations.selected
  );

  return !organization ? null : (
    <Card>
      <CardHeader>
        <CardTitle>{organization.name}</CardTitle>
      </CardHeader>
      <CardBody>
        <p>{organization.summary}</p>
      </CardBody>
    </Card>
  );
};
