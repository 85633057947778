import React, { FC, useState } from 'react';
import { Formik, Form } from 'formik';
import {
  Row,
  Column,
  Card,
  CardBody,
  CardActions,
  CardAction,
  Input,
  defaultTheme,
  CardHeader,
  CardTitle,
} from '@nimles/react-web-components';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  CheckboxField,
  TextAreaField,
  InputField,
  RadioField,
} from '@nimles/react-web-forms';
import { ProductGroupWithProductProperties } from '../../../types';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import {
  createUser,
  signInUser,
  createOrder,
  submitOrder,
  createQuote,
  createQuoteRequest,
  createFile,
  submitQuoteRequest,
  submitQuote,
  updateQuote,
} from '@nimles/react-redux';
import {
  FileModel,
  OrderModel,
  OrganizationModel,
  ProductGroupModel,
  QuoteLineModel,
  QuoteModel,
  QuoteRequestModel,
  UserModel,
} from '@nimles/models';
import { navigate } from 'gatsby';
import { RootState } from '../../../redux/types';
import { createPassword } from '../../../utils';
import FileField from '../../fields/FileField';
import { QuillField } from '../../fields/QuillField';
import { CardArea, CardAreaTitle } from '../FormCard';
import { FieldSet, FieldTitle } from '../../fields/FieldSet';

interface Props {
  quote?: QuoteModel;
  onSubmitted?: (values: any) => void;
  quoteRequestId?: string;
  productGroupId?: string;
}

export const QuoteForm: FC<Props> = ({ quote, onSubmitted }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector<RootState, UserModel>(
    ({ currentUser }) => currentUser.user
  );
  const organization = useSelector<RootState, OrganizationModel>(
    ({ organizations }) => organizations.selected
  );
  const publicProductGroups = useSelector<RootState, ProductGroupModel[]>(
    ({ publicProductGroups }) => publicProductGroups.values
  );
  const files = useSelector<RootState, FileModel[]>(
    ({ files }) => files.values
  );

  const getProductGroup = (productGroupId) =>
    publicProductGroups.find((p) => p.id === productGroupId);

  const handleSubmit = async (
    values,
    { setSubmitting, setErrors, setStatus }
  ) => {
    try {
      const lines = await Promise.all<QuoteLineModel>(
        values.lines.map(
          async ({ files, ...line }): Promise<QuoteLineModel> => ({
            ...line,
            fileIds: await Promise.all(
              files.map(
                async (file: FileModel): Promise<string> => {
                  if (file.id) {
                    return file.id;
                  }

                  const fileResponse: any = await dispatch(
                    createFile({ ...file, accessibility: 'PublicExplicit' })
                  );

                  return fileResponse.id;
                }
              )
            ),
          })
        )
      );

      await dispatch(
        updateQuote({
          ...quote,
          head: {
            ...quote.head,
            message: values.message,
          },
          lines,
        })
      );

      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      console.error(error);
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  const values = {
    lines: quote.lines?.map(({ fileIds, ...line }) => {
      return {
        ...line,
        files:
          fileIds?.map(
            (fileId) => files.find(({ id }) => id === fileId) ?? { id: fileId }
          ) ?? [],
      };
    }),
  };

  return (
    <div>
      <Formik
        initialValues={values}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
        enableReinitialize
        render={({ values, handleSubmit, isSubmitting }) => {
          return (
            <Form
              onSubmit={handleSubmit}
              name="simpleForm"
              className="quote-request-form"
            >
              <Card>
                <CardHeader>
                  <CardTitle>{t('title.quote')}</CardTitle>
                </CardHeader>
                {values.lines.map(({ productGroupId }, index) => (
                  <CardArea key={index}>
                    <CardAreaTitle>
                      {getProductGroup(productGroupId)?.name}
                    </CardAreaTitle>
                    <FieldSet>
                      <FieldTitle></FieldTitle>
                      <QuillField
                        name={`lines.${index}.description`}
                        label={t('prop.description')}
                        placeholder={t('prop.description')}
                      />
                    </FieldSet>
                    <FieldSet>
                      <FieldTitle></FieldTitle>

                      <InputField
                        name={`lines.${index}.totalNetPrice`}
                        label={t('prop.price')}
                        placeholder={t('prop.price')}
                        type="number"
                        align="right"
                        suffix="€"
                      />
                    </FieldSet>
                    <FieldSet>
                      <FileField
                        name={`lines.${index}.files`}
                        label={t('prop.files')}
                        placeholder={t('text.filesPlaceholder')}
                        multi
                      />
                    </FieldSet>
                  </CardArea>
                ))}
                <CardActions>
                  <CardAction
                    secondary
                    raised
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {t(
                      isSubmitting ? 'action.savingQuote' : 'action.saveQuote'
                    )}
                  </CardAction>
                  <CardAction
                    primary
                    raised
                    disabled={isSubmitting}
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(submitQuote(quote.id));
                    }}
                  >
                    {t(
                      isSubmitting ? 'action.sendingQuote' : 'action.sendQuote'
                    )}
                  </CardAction>
                </CardActions>
              </Card>
            </Form>
          );
        }}
      />
    </div>
  );
};
