import React, { useEffect } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Column,
  List,
  ListItem,
  Row,
} from '@nimles/react-web-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/types';
import { navigate } from 'gatsby';
import { loadQuotes } from '@nimles/react-redux';
import moment from 'moment';
import { CategoryModel, ProductGroupModel, QuoteModel } from '@nimles/models';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { NewQuote } from './NewQuote';
import { CompanyRoute } from '../CompanyRoute';
import { Router } from '@reach/router';
import { Quote } from './Quote';

const QuoteList = ({ path }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const quotes = useSelector<RootState, QuoteModel[]>(
    ({ quotes }) => quotes.values
  );
  const publicCategories = useSelector<RootState, CategoryModel[]>(
    ({ publicCategories }) => publicCategories.values
  );
  const publicProductGroups = useSelector<RootState, ProductGroupModel[]>(
    ({ publicProductGroups }) => publicProductGroups.values
  );

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('title.quotes')}</CardTitle>
      </CardHeader>
      <CardBody>
        <List>
          {quotes.map(({ id, head: { quoteNumber, submittedDate }, lines }) => {
            const groups = lines.map(
              ({ id, categoryId, productGroupId }) =>
                `${
                  publicCategories.find(({ id }) => id === categoryId)?.name ??
                  ''
                } ${
                  publicProductGroups.find(({ id }) => id === productGroupId)
                    ?.name ?? ''
                }`
            );

            return (
              <ListItem
                key={id}
                onClick={() => navigate('/company/quotes/' + id)}
              >
                <Row justify="space-between">
                  <Column>
                    {lines.map(({ id, categoryId, productGroupId }) => {
                      const category = publicCategories.find(
                        ({ id }) => id === categoryId
                      );
                      const productGroup = publicProductGroups.find(
                        ({ id }) => id === productGroupId
                      );

                      return (
                        <div>
                          <strong>{category?.name ?? ''}</strong>
                          <div>{productGroup?.name ?? ''}</div>
                        </div>
                      );
                    })}
                  </Column>
                  <Column>{moment(submittedDate).format('MMM D')}</Column>
                </Row>
              </ListItem>
            );
          })}
        </List>
      </CardBody>
    </Card>
  );
};

export const Quotes = () => {
  return (
    <Router>
      <CompanyRoute path="/:quoteId" component={Quote} />
      <QuoteList path="/" />
    </Router>
  );
};
